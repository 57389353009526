/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

@import "../../../../../node_modules/@swimlane/ngx-datatable/index.css";
@import "../../../../../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../../../../../node_modules/@swimlane/ngx-datatable/assets/icons.css";